@warningColor: rgba(255, 177, 86, 1);
@warningBackgroundContainerColor: rgba(255, 251, 245, 1);
@infoIconColor: #03729f;
@infoBorderContainerColor: #03729f;
@infoBackgroundContainerColor: rgba(245, 242, 255, 1);

.message-warning-disabled-container {
  background: @warningBackgroundContainerColor !important;
  border: 1px solid @warningColor;
  border-radius: 10px;
  padding: 8px 16px 8px 8px !important;
  margin:auto;
  margin-bottom: 16px;
  align-items: center;
  width: 100%;
  .GenericCard-container--information{
    gap: 13px;
  }
}

.GenericCard-container.message-warning-disabled-container {
  border: 1px solid @warningColor;
  border-radius: 10px;
}

@media (max-height: 616px) {
  .message-warning-disabled-container {
    margin: 12px 0;
  }
}

.message-info-disabled-container {
  background: @infoBackgroundContainerColor !important;
  border: 1px solid @infoIconColor !important;
  border-radius: 10px;
  padding: 8px 16px 8px 8px !important;
  margin:auto;
  margin-bottom: 16px;
  align-items: center;
  .GenericCard-container--information{
    gap: 13px;
  }
}

.wrapper-message{
  display: flex;
  flex-direction: column;
  padding-left: 14px;
}
.icon-warning-disabled {
  align-items: center;
  display: flex;
  font-size: 44px;
  text-align: left;
  color: @warningColor;
}

.icon-info-disabled {
  align-items: center;
  display: flex;
  font-size: 38px;
  text-align: left;
  color: @infoIconColor;
}

.message-info-disabled {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.1px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  a {
    color: black;
  }
}
.submessage-info-disabled {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.1px;
  margin-bottom: 0;
}

.wrapper-disabled {
  margin-top: 12px;
  opacity: 0.5;
  pointer-events: none;
}

@primary-color: #03729F;