.protable-tenant-setting--wrapper__description {
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 21.82px;
}
.protable-tenant-setting--wrapper {
  background: #fff;
  padding: 20px;
  border-radius: 8px;

  .protable-tenant-setting {
    border-radius: 10px;
    padding-bottom: 16px;
    .ant-pro-card {
      border-radius: 10px;
    }
    .ant-table,
    .ant-table-content,
    .ant-table-thead {
      border-radius: 10px 10px 0 0;
    }
    .ant-pro-table-search-query-filter {
      margin-bottom: 0.5rem;
    }
    .ant-pro-query-filter {
      box-shadow: 0px 4px 20px 0px #0000001a;
      border-radius: 10px;
      .name-input {
        border-radius: 5px;
      }
    }
    .ant-pro-card-body {
      border-radius: 10px;
      border: 1px solid #eeeeee;
      padding: 0px;
      box-shadow: 0px 4px 20px 0px #0000001a;
      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: transparent;
      }
      .ant-table.ant-table-middle {
        .ant-table-thead > tr > th,
        .ant-table-tbody > tr > td {
          padding: 0.75rem 2rem;
        }
      }
    }
    .ant-switch-checked {
      background-color: #3baa61;
    }
    .ant-form-item {
      margin: 0;
    }
    .ant-select-selection-item {
      text-align: left;
    }
  }
}

.ant-select-selector {
  border-radius: 5px !important;
  border-color: rgba(35, 35, 35, 0.15) !important;
  border-width: 1px !important;
}

.tenant-setting-save-button {
  text-align: end;
  margin-top: 8px;
  .btn-save {
    background-color: #d32210 !important;
    color: white !important;
    border-radius: 5px !important;
  }

  .btn-save.disabled {
    opacity: 0.5;
  }
}

.tenant-setting-section-title {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 0px;
  margin: 0;
}

.h5 {
  font-weight: 700;
}

@primary-color: #03729F;