.tenant-edit-admin {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    &--cancel {
      background-color: #fff;
    }
  }

  &__tenant-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
  }
}

@primary-color: #03729F;