.setting-select {
  width: 250px;
  .ant-select-item-option-content {
    text-align: left;
  }
}

.margin-component{
  margin: 0px !important;
}

@primary-color: #03729F;