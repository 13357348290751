.notification {
  > .notification__container-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > .notification__container-content__title {
      font-weight: 700;
      font-size: 13px;
      letter-spacing: 0.07em;
    }
    > .notification__container-content__description {
      font-size: 13px;
      letter-spacing: 0.07em;
    }
  }
}

.pointer {
  cursor: pointer;
}

.notification:hover {
  transition: background-color ease-in 0.2s;
  background-color: #f0f2f5;
}

@primary-color: #03729F;