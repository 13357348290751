.container_reset_password_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    height: 20%;
    object-fit: contain;
  }
  .container_reset_password_content__title {
    margin-bottom: 16px;
  }
  > .container_text_reset_password_content {
    max-width: 300px !important;
    color: white;
  }
  .container_form_reset_password_content {
    width: 100%;
    > form {
      margin: 0 auto;
      max-width: 300px !important;
      > .items {
        display: flex;
        flex-direction: column;
        min-height: 35vh;
        margin-top: 38px;
        > .ant-form-item {
          > .ant-row.ant-form-item-row {
            > .ant-col.ant-form-item-control {
              > .ant-form-item-control-input {
                > .ant-form-item-control-input-content {
                  > .ant-input {
                    background-color: transparent;
                    border-radius: 5px;
                  }
                  input:-webkit-autofill,
                  input:-internal-autofill-selected,
                  input:-webkit-autofill:hover,
                  input:-webkit-autofill:focus,
                  input:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0)
                      inset;
                    box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
                  }
                }
              }
            }
          }
        }
      }
      > .ant-form-item-control {
        justify-content: center;
      }
      .btn_reset_password {
        width: 100%;
        border-radius: 5px;
        border-color: transparent;
        background-color: #d32210;
      }
      .btn_reset_password:hover {
        opacity: 0.7;
      }
      .btn_back {
        width: 100%;
        color: rgba(28, 27, 31, 1);
        font-weight: bold;
        margin: 16px 0 0 0;
        padding: 0;
        height: auto;
      }
      @media (max-width: 256px) {
        .items {
          min-height: 40vh;
        }
      }
    }
  }
  .btn_reset_password-disabled{
    width: 100%;
    border-radius: 5px;
    background-color: #d32210;
    opacity: 0.5;
    color:white;
    
  }
  .btn_reset_password-disabled:hover{
    width: 100%;
    border-radius: 5px;
    background-color: #d32210;
    opacity: 0.5;
    color:white;
  }

  .reset_password_errorMsj{
    text-align: left;
  }
}

@primary-color: #03729F;