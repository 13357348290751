.automation-control {
  &__card {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 3px;
    padding: 10px 8px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    cursor: pointer;

    &--active {
      border: 1px solid #004662;
    }

    &__content {
      display: flex;
      gap: 3px;
    }
    &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
    &__title {
      font-size: 13px;
      font-weight: 700;
      margin-bottom: 0;
    }
    &__text {
      font-size: 13px;
      margin: 0;
    }

    &__badge {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 111px;
      height: 24px;
      background-color: #004662;
      font-size: 12px;
      font-weight: 700;
      border-radius: 15px;
      color: white;
    }
  }
}

@primary-color: #03729F;