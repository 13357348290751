.eneable2fa__card {
  width: 100%;
  .ant-card-head-title {
    white-space: unset;
  }
  p {
    margin-bottom: 30px;
  }
  button {
    white-space: normal;
    height: fit-content;
  }
}

@primary-color: #03729F;