@import '../../../globalVariables.less';

.ant-upload.ant-upload-drag.receipt-tray-dragger {
  border-radius: 4px;
  height: 100%;
  .ant-upload {
    display: flex;
    justify-content: center;
  }
  .ant-upload-drag-container {
    display: flex;
    gap: 10px;
    align-items: center;
    text-align: left;
    .ant-upload-drag-icon {
      font-size: 2.25rem;
    }
    .ant-upload-text {
      font-size: 14px;
      font-weight: 700;
      color: @Blue;
      margin: 0;
      .ant-upload-subtext{
        font-weight: 400;
        font-size: 12px;
        color: rgba(28, 27, 31, 0.6);
        line-height: 16px;
      }
    }
    
  }
}

.ant-upload-list-picture-container {
  .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
    background-color: #ffffff;
    border-style: none;
    border: 1px solid #eeeeee;
    border-radius: 2px;
    .anticon-delete {
      color: @Red;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }
}

@primary-color: #03729F;