.business-setting-restore-modal {
  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    &__title {
      display: flex;
      align-items: center;
      gap: 5px;

      span {
        margin: 0;
      }
    }

    &__text {
      margin: 0;
    }

    &__actions {
      display: flex;
      gap: 15px;
      justify-content: flex-end;
      width: 100%;
    }
  }
}

@primary-color: #03729F;