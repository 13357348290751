.business-settings {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__header {
    &__title {
      font-size: 16px;
      font-weight: 700;
    }

    &__text {
      font-size: 13px;
      margin-bottom: 0;
    }
  }

  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .icon-disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.3;
    filter: grayscale(100%);
  }
}

.icon {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  cursor: default;
}

.help-icon {
  display: flex;
  align-items: center;
}

@primary-color: #03729F;