.container-form-profile {
  display: flex;
  justify-content: center;
  > .content-container-form-profile {
    width: 50%;
    .content-container-center {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  .qr-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .ant-form {
    > button,
    > div {
      margin-bottom: 20px;
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      .save-btn {
        background-color: #d32210;
        border-color: transparent !important;
      }
      .save-btn:hover {
        opacity: 0.8;
      }
    }
  }

  .container-avatar {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .avatar {
      position: relative;
      right: -50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .profile-icon{
        font-size: 74px;
      }
    }
    .button {
      width: 130px;
      height: 130px;
      position: relative;
      opacity: 0%;
      right: 50%;
      z-index: 3;
      border-radius: 65px;

      &.show-button:hover {
        position: relative;
        opacity: 70%;
        background-color: white;
      }
    }
  }

  .profile-btn-align{
    display: flex;
    align-items: center;
    gap: 4px;
    .profile-btn-align-icon{
      font-size: 18px;
    }
  }

  @media (max-width: 1100px) {
    .content-container-form-profile {
      width: 75%;
    }
    .ant-form {
      button {
        width: 100%;
      }
    }
  }
  @media (max-width: 600px) {
    .content-container-form-profile {
      width: 100%;
    }
    .ant-row,
    .buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-right: 0;
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }
}

@primary-color: #03729F;