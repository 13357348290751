
.containerChecks{
    display: flex;
    flex-direction: column;
    label{
      margin-left: 0 !important;  
    }
}

.modal-footer-alert {
  font-size: 10px;
  color: gray;
  margin-top: 20px;
}

.ant-modal-footer {
  .save-btn {
    background-color: #d32210;
    border-color: #d32210 !important;
  }
}
@primary-color: #03729F;