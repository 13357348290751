.register-page {
  display: flex;
  flex-direction: column;
  align-items: center;
   .register-page__container {
    width: 100%;
    max-width: 350px;
    min-width: 334px;
    margin: auto;
    > .ant-form.ant-form-vertical {
      > .register-page__container__divider {
        border-top: 1px solid white;
      }
      > .ant-form-item {
        margin-bottom:16px;
        > .ant-row.ant-form-item-row {
          > .ant-col.ant-form-item-label {
            > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
              display: none;
            }
            > label {
              > .register-page__container__label-form {
                font-weight: normal;
                color:rgba(0, 0, 0, 1);
              }
            }
          }
          > .ant-col.ant-form-item-control {
            > .ant-form-item-control-input {
              > .ant-form-item-control-input-content {
                > .ant-input {
                  background-color: transparent;
                  border-radius: 5px;
                }
                input:-webkit-autofill,
                input:-internal-autofill-selected,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0)
                    inset;
                  box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
                }
                > .register-page__container__message-regex {
                  display: flex;
                  > p {
                    text-align: left;
                  }
                }
                > .register-page__container__button-save {
                  width: 100%;
                  border-radius: 5px;
                  border-color: transparent;
                  background-color: #d32210;
                }
                .register-page__container__button-save:hover {
                  opacity: 0.8;
                }
                > .register-page__container__terms {
                  display: flex;
                  justify-content: flex-start;
                  color:rgba(28, 27, 31, 1);
                  text-align: left;
                  margin: 0 0 16px;
                  > .register-page__container__terms__checkbox {
                    margin-right: 10px;
                    width: 15px;
                  }
                  > .register-page__container__terms__description {
                    width: 205px;
                    line-height: 20px;
                    > span {
                      opacity: 0.8;
                    }
                    > a {
                      text-decoration: underline;
                      text-decoration-color: rgba(255, 255, 255, 0.4);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .register-page__container__title {
      margin-bottom: 16px;
      font-size: 30px;
      font-weight: 700;
      line-height: 40.92px;
    }
  }

  .ant-divider-horizontal{
    margin:16px 0 10px 0;
  }

  .register-page__container__button-save-disabled{
    
    width: 100%;
    border-radius: 5px;
    background-color: #d32210;
    opacity: 0.5;
    color:white;
    
  }

  .register-page__container__button-save-disabled:hover{
    width: 100%;
    border-radius: 5px;
    background-color: #d32210;
    opacity: 0.5;
    color:white;
  }
  .register-page__container__buttonGroup-sign-in{
    color: rgba(28, 27, 31, 1);
    .register-page__container__button-sign-in {
      background-color: transparent;
      text-align: center;
      padding: 0;
      height: auto;
    }
  } 
  .register-page_errorMsj{
    text-align: left;
  }
}

@primary-color: #03729F;