.indicators {
  padding: 0 13px;
  > .indicators__header {
    > .indicators__header__form-filter {
      justify-content: end;
      margin-right: -13px;
      > .ant-form-item {
        margin-right: 0;
        &.ant-form-item-has-error {
          margin-bottom: 0;
          .ant-form-item-margin-offset {
            margin-bottom: 0 !important;
          }
        }
      }
      > .indicators__header__form-filter__divider {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
  > .indicators__divider {
    width: calc(100% + 26px);
    margin-left: -13px;
  }
  > .indicators__content {
    > .ant-row {
      > .ant-col {
        > .indicators__content__container-cards {
          display: flex;
          flex-direction: column;
        }
      }
      > .ant-col:first-child {
        padding-left: 0px !important;
      }
      > .ant-col:last-child {
        padding-right: 0px !important;
      }
    }
    > .indicators__content__container-cards-statistics-entities {
      margin-top: 26px;
      border-radius: 10px;
      background: #ffffff;
      padding: 15px 0;
      .indicators__content__container-cards-statistics-entities__col-cards:not(:last-child) {
        border-right: 1px solid #eeeeee;
      }
    }
  }
}

@primary-color: #03729F;