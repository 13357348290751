.secondary-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

@media screen and (max-width: 400px) {
  .secondary-header {
    margin-bottom: 15px;
  }
}

@primary-color: #03729F;