.field-configuration-modal {
  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    padding: 0 16px;

    &__input {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;

      &__label {
        font-size: 13px;
      }
    }

    &__actions {
      display: flex;
      gap: 15px;
      justify-content: flex-end;
      width: 100%;
    }
  }
}

@primary-color: #03729F;