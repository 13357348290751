.collapse-with-table {
  box-shadow: 0px 5px 15px 2px #0000000d;
  border-radius: 10px;
  border: 1px solid #eeeeee;
  .collapse-with-table__panel__table__row--expandable {
    cursor: pointer;
  }
  .collapse-with-table__panel {
    border: none;
    .ant-collapse-header {
      display: flex;
      align-items: center;
      &[aria-expanded='true'] {
        padding-bottom: 0px;
      }
      .collapse-with-table__panel__extra {
        font-size: 16px;
        color: black;
        font-weight: 400;
      }
      .ant-collapse-extra p {
        margin: 0px;
      }
    }
    .ant-collapse-header-text {
      font-size: 13px;
      font-weight: 700;
    }
  }
  .ant-pro-card-body {
    border: 1px solid #eeeeee;
    padding: 0px;
    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      background-color: transparent;
    }
  }
}

@primary-color: #03729F;