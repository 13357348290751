.billing-info {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__form-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 !important;
  }

  .ant-form-item-label {
    padding: 0 !important;
  }

  .ant-form-item-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@primary-color: #03729F;