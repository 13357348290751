.component-wrapper {
  background: #fff;
  box-shadow: 0px 4px 20px 0px #0000001a;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 21.82px;
  }
}

@primary-color: #03729F;