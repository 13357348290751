.export-send-email-modal{
    .ant-form-item-row{
        display: flex;
        flex-flow: row wrap;
        min-width: 0;
        flex-direction: column;
        align-items: stretch;
        text-align: start;
        .ant-form-item-label {
            text-align: left;
        }
    }
}
@primary-color: #03729F;