.AboutPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 550px;
  margin: auto;
  text-align: center;
  justify-content: space-between;
  height: calc(100vh - 112px);
  &__contact-container {
    width: 100%;
    &__title {
      > p {
        font-weight: bold;
      }
    }
  }
  &__people-container {
    width: 500px;
    padding: 0 24px;
    overflow: hidden;
    height: 100%;
    &__container-title {
      background-color: #f0f2f5;
      &__title {
        > p {
          font-weight: bold;
        }
      }
    }
    &__container-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      > img {
        width: 100px;
        height: auto;
      }
    }

    &__people-info {
      height: max-content;
      text-align: center;
      -moz-transform: translateY(500px);
      -webkit-transform: translateY(500px);
      transform: translateY(500px);

      -moz-animation: scrollUp 22s linear infinite;
      -webkit-animation: scrollUp 22s linear infinite;
      animation: scrollUp 22s linear infinite;

      &:hover {
        height: 100%;
        -moz-animation: none;
        -webkit-animation: none;
        animation: none;
        -moz-transform: none;
        -webkit-transform: none;
        transform: none;
        overflow-y: scroll;
        padding: 0 5px;
        z-index: 5;
        position: relative;

        /* Chrome, Edge, and Safari */
        &::-webkit-scrollbar {
          width: 3px;
          margin-left: 19px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: @primary-color;
          border-radius: 15px;
          border: none;
        }
      }

      &__people-info-container {
        display: flex;
        justify-content: space-between;
        > div {
          width: max-content;
          margin-bottom: 16px;
          align-items: center;
          width: 50%;
        }
        &__people-name {
          text-align: left;
        }
        &__people-job-position {
          text-align: right;
          &__job-position {
            width: 100%;
          }
        }
      }
    }
  }
}

/* for Firefox */
@-moz-keyframes scrollUp {
  from {
    -moz-transform: translateY(500px);
  }
  to {
    -moz-transform: translateY(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes scrollUp {
  from {
    -webkit-transform: translateY(500px);
  }
  to {
    -webkit-transform: translateY(-100%);
  }
}

@keyframes scrollUp {
  from {
    -moz-transform: translateY(500px);
    -webkit-transform: translateY(500px);
    transform: translateY(500px);
  }
  to {
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@primary-color: #03729F;