@import '~antd/lib/style/themes/default.less';
@headerMenuIconColor: #03729F;

header.HeaderComponent {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  > .HeaderComponent__container-operations {
    display: flex;
    align-items: center;
    gap: 12px;
    .tenant-selector-container {
      display: flex;
      margin-left: auto;
      align-items: center;
      gap: 1rem;
    }
    > div.AvatarComponent {
      .Avatar {
        transform: translateY(-2px);
      }
      .AvatarName {
        padding-left: 6px;
        color: @text-color;
      }
      > .ant-dropdown-trigger {
        display: flex;
        align-items: center;
      }
    }
  }
  > .HeaderRouteLabel {
    display: flex;
    min-width: 0;
    .menuTrigger{
      position: static;
      .trigger {
        cursor: pointer;
        transition: color 0.3s;
        color: @headerMenuIconColor;
      }
      > .trigger:hover {
        opacity: 0.8;
      }
    }
    justify-content: center;
    align-items: center;
    .currentRouteLabelName {
      padding-left: 8px;
      font-size: 20px;
      width: min-content;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .anticon {
      font-size: 25px;
    }
  }
}

@primary-color: #03729F;