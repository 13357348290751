.settings-header {
  z-index: 10;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding: 15px;
  border-radius: 10px;

  &__top {
    display: flex;
    justify-content: space-between;
    z-index: 20;
    padding: 0;

    &__content {
      &__title {
        font-size: 16px;
        font-weight: 700;
      }

      &__text {
        font-size: 13px;
        margin-bottom: 0;
      }
    }

    &__actions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}

.settings-header-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 25;
  top: 0;
  transition: transform 0.2s ease;
  transform: translateY(-100%);
  padding: 15px;
  background-color: #fff;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.05);

  &--sticky {
    transform: translateY(0);
  }

  &__left {
    display: flex;
    gap: 10px;
    align-items: center;

    &__description {
      font-size: 13px;
      margin-bottom: 0;
    }
  }
}

@primary-color: #03729F;