.exception-manager {
  &-white {
    .ant-result-title {
      color: white;
    }
  }
  &-black {
    .ant-result-title {
      color: black;
    }
  }
}

@primary-color: #03729F;