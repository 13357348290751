.container_set_password_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  
  > .container_text_set_password_content {
    max-width: 300px !important;
    color: white;
  }
  .container_form_set_password_content {
    width: 100%;
    > form {
      margin: 0 auto;
      max-width: 300px !important;
      > .items {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 4vh;
        margin-top: 3vh;
        > .ant-form-item {
          margin-bottom: 16px;
          > .ant-row.ant-form-item-row {
            > .ant-col.ant-form-item-control {
              > .ant-form-item-control-input {
                > .ant-form-item-control-input-content {
                  > .ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-has-feedback {
                    background-color: transparent;
                    border-radius: 5px;
                    > input {
                      background-color: transparent;
                    }
                    input:-webkit-autofill,
                    input:-internal-autofill-selected,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    input:-webkit-autofill:active {
                      -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0)
                        inset;
                      box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
                    }
                  }
                }
              }
            }
          }
          > .complete-register-page__container-form__password_requirements {
            color: white;
            text-align: left;
          }
        }
      }
      .btn_set_password {
        width: 100%;
      }

      @media (max-width: 256px) {
        .items {
          min-height: 40vh;
        }
      }
    }
  }

  .btn_set_password-disabled{
    width: 100%;
    border-radius: 5px;
    background-color: #d32210;
    opacity: 0.5;
    color:white;
    
  }
  .btn_set_password-disabled:hover{
    width: 100%;
    border-radius: 5px;
    background-color: #d32210;
    opacity: 0.5;
    color:white;
  }
  .btn_set_password, .btn_set_password_password{
    width: 100%;
    border-radius: 5px;
    background-color: #d32210;
    color:white;
    border-color: transparent;
  }

  .btn_set_password_password:hover{
    width: 100%;
    border-radius: 5px;
    background-color: #d32210;
    opacity: 0.7;
    color:white;
  }
  .container_set_password_password_requirements{
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 38px;
  }
  .ant-form-item-explain.ant-form-item-explain-connected{
    text-align: left;
  }
  .text_set_password_content{
    margin-bottom: 38px;
  }
  .container_set_password_content__title {
    font-size: 30px;
    font-weight: 700;
    line-height: 40.92px;
  }

}
.set-password__container-message-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title-invalid-link {
    letter-spacing: 0.07rem;
  }
}

.container-invalid-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;

  .btn_return_login{
      width: 100%;
      border-radius: 5px;
      background-color: #d32210;
      color:white;
      border-color: transparent;
    }
    .btn_return_login:hover{
      width: 100%;
      border-radius: 5px;
      background-color: #d32210;
      opacity: 0.7;
      color:white;
    }
  
}

@primary-color: #03729F;