@font-face {
  font-family: 'Nunito';
  src: local('Nunito-VariableFont_wght') format('truetype'),
    url('../../../assets/fonts/Nunito-VariableFont_wght.ttf');
  font-weight: normal;
}
@font-face {
  font-family: 'Nunito';
  src: local('Nunito-VariableFont_wght') format('truetype'),
    url('../../../assets/fonts/Nunito-VariableFont_wght.ttf');
  font-weight: bold;
}

.DeviceUnavailableCheck-cover {
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #262b47 !important;
  align-items: center;
  z-index: 9999;
  display: block;
  text-align: center;
  justify-content: flex-start;
  padding: 2vh 0vw;
}

.DeviceUnavailableCheck-container {
  width: 90%;
  height: 98%;
  max-width: 100%;
  max-height: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: auto;
  color: #000 !important;
  font-family: 'Nunito';
  font-style: normal;
  line-height: normal;
  font-weight: normal;
}

.DeviceUnavailableCheck-container--image {
  height: 20px;
  margin-bottom: 45px;
  margin-top: 10px;
}

.DeviceUnavailableCheck-container--title {
  text-align: center;
  font-size: 28px;
  margin: 0;
  margin-bottom: 35px;
  color: white;
  font-weight: 700;
  width: 100%;
  letter-spacing: 0.5px;
}

.DeviceUnavailableCheck-container--subtitle1 {
  margin: 0;
  text-align: center;
  font-size: 20px;
  color: white;
  margin-top: 25px;
}

.DeviceUnavailableCheck-container--subtitle2 {
  margin: 0;
  text-align: center;
  font-size: 20px;
  color: white;
}

.image-svg {
  width: 100%;
  height: auto;
}

.radial-gradient-blue {
  width: 819px;
  height: 790px;
  position: absolute;
  left: -284px;
  top: -154px;
  border-radius: 819px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #03719e 16%,
    rgba(3, 113, 158, 0) 100%
  );
  filter: blur(50px);
  z-index: -1;
}

.radial-gradient-red {
  width: 606px;
  height: 605px;
  position: absolute;
  right: -221px;
  bottom: 2px;
  border-radius: 606px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #d32210 0%,
    rgba(211, 34, 16, 0) 100%
  );
  filter: blur(100px);
  z-index: -1;
}

/* Media Queries */
/* Para dispositivos móviles */
@media (max-width: 768px) {
  .image-svg {
    width: 70%;
    min-height: 300px;
  }

  .DeviceUnavailableCheck-container--title {
    width: 100%;
  }

  .DeviceUnavailableCheck-container--subtitle2 {
    margin-bottom: 7% !important;
  }
}

/* Para tablets*/
@media (min-width: 769px) and (max-width: 1024px) {
  .DeviceUnavailableCheck-container--image {
    height: 25px;
  }

  .DeviceUnavailableCheck-container--title {
    font-size: 40px;
    max-width: 70%;
  }

  .DeviceUnavailableCheck-container--subtitle1 {
    font-size: 28px;
    margin-top: 40px;
  }
  .DeviceUnavailableCheck-container--subtitle2 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .image-svg {
    width: 60% !important;
    min-height: 550px;
  }

  .radial-gradient-blue {
    width: 919px !important;
    height: 890px !important;
    left: -284px !important;
    top: -154px !important;
    position: fixed !important;
  }

  .radial-gradient-red {
    width: 906px !important;
    height: 905px !important;
    bottom: 80px !important;
    right: -200px !important;
    position: fixed !important;
  }

  .custom-form {
    width: 80% !important;
  }
}

@media (min-width: 1025px) {
  /* Para pantallas más grandes */
  .image-svg {
    width: 50%;
    min-height: 600px;
  }
}

// estilos para cuando se habilite el formulario para ingresar el mail que por el momento lo sacamos
.custom-input {
  height: 40px;
  width: 100% !important;
  background: transparent !important;
  color: #fafafa !important;
}

.custom-input:focus {
  background: transparent !important;
}

.custom-input:valid {
  background: transparent !important;
}

.custom-form {
  width: 90% !important;
}

.custom-input::placeholder {
  color: #fafafa !important;
  opacity: 0.5 !important;
  font-size: 20px !important;
}

.custom-button {
  font-size: 20px;
  height: 40px;
  margin-top: -4px;
  background: #d32210;
  width: 100%;
  border: 1px solid #d32210;
}

.custom-button:hover {
  background-color: #d32210;
}

@primary-color: #03729F;