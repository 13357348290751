div.LogoComponent {
  margin: 20px;
  letter-spacing: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .menu-logo{
    width: 81.5px;
  }
}

@primary-color: #03729F;