@import '~antd/lib/style/themes/default.less';

.WizardLayout {
  > aside.ant-layout-sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    color: white;
    white-space: nowrap;
    z-index: 6;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .ant-layout-sider-children {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 30px;
    }
  }
  > section.ant-layout {
    color: inherit;
    height: 100vh;
    overflow-y: auto;
    > header.ant-layout-header {
      background-color: white;
      padding-left: 48px;
    }
    > .backdrop-content {
      display: none;
      width: 100%;
      height: 100%;
      background-color: rgba(66, 66, 66, 0.589);
      position: absolute;
      z-index: 5;
    }
    > .ant-layout-content {
      overflow: initial;
      margin: 24px;
    }
    transition: all 0.2s;
  }
  .env-tag {
    position: absolute;
    top: 0px;
    right: 1vw;
  }
  .site-layout {
    position: relative;
    flex: none;
  }
}

@media screen and (max-width: 400px) {
  .WizardLayout {
    > section.ant-layout {
      > .ant-layout-content {
        margin: 0px;
      }
    }
  }
}

@primary-color: #03729F;