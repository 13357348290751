.ErrorCard-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  gap: 16px;
  margin-bottom: 0.5rem;
  background: #fffbf5;
  border: 1px solid #eeeeee;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  &--title {
    font-weight: 700;
    margin-bottom: 0px;
  }
  &--description {
    margin-bottom: 0px;
  }
}

@primary-color: #03729F;