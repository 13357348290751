.card-statistics-entities {
  display: flex;
  flex-direction: column;
  padding: 0 21px;
  > .card-statistics-entities__container-icon {
    color: #03729f;
  }
  > .card-statistics-entities__container-title {
    letter-spacing: 0.07em;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
  }
  > .card-statistics-entities__container-frequency {
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    letter-spacing: 0.07em;
    color: #004662;
  }
}

@primary-color: #03729F;