.main-card-validate2FA {
  max-width: 90vw;
  width: 404px;
  margin: 0 auto;
  border: 1px solid #eee;
  border-radius: 10px;
  box-shadow: 5px 5px 30px rgb(0 0 0 / 5%);
  text-align: left;
  .ant-card-head {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 2px solid #eee;
    background-color: #fff;
    max-height: 47px;
    display: flex;
    align-items: center;
    padding:0 16px;
    .ant-card-head-title {
      white-space: unset;
      font-size: 20px;
      padding: 0;
      color: rgba(0, 0, 0, 0.7);
      font-weight: 400;
    }
  }
  .container-buttons {
    display: flex;
    align-items: center;
    margin-top: 32px;
    justify-content: flex-end;
    width: 100%;
    button.ant-btn-default {
      margin-right: 10px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  p {
    margin-bottom: 10px;
  }

  .input-token {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .container-input {
      margin-top: 10px;
      height: 70px;

      > .warning-input {
        font-size: 10px;
        color: #d32210;
      }
    }

    > .container-buttons {
      display: flex;
    }
  }

  .ant-card-body {
    padding: 16px;
  }
  .enter-validation-code {
    margin-right: 0;
    border-radius: 5px;
    border-color: transparent;
    background-color: #d32210;
    color:white;
  }

  .enter-validation-code:hover {
    opacity: 0.7;
  }
  @media screen and (max-width: 370px) {
    .ant-card-body {
      padding: 16px;
    }

    .input-token {
      > .container-input {
        .react-code-input {
          input {
            height: 30px !important;
            width: 30px !important;
            margin: 2px !important;
            padding-left: 5px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 40rem) {
  .main-card-generate2FA {
    .ant-card-head-title {
      font-size: 16px !important;
    }
  }
}

@primary-color: #03729F;