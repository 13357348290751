#direct-whatsapp {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 16px;
  right: 15px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  object-fit: contain;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: bottom 0.5s ease 0s;
}
#direct-whatsapp img {
height: 50px;
border-radius: 50%;
width: auto;
}
#direct-whatsapp:hover {
  bottom: 20px;
}
@primary-color: #03729F;