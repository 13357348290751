.decision-table-page__row-incomplete {
  > .ant-table-cell {
    background-color: #fffbf5 !important;
  }
}
.decision-table-page__row-incomplete-icon {
  color: #ffb156;
  font-size: 20px;
}
.title-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.protable-data-relation {
  .data-relation-pro-table-search-filter-text {
    font-weight: bold;
    padding-top: 16px;
    margin-left: 24px;
  }

  .dataRelation-action-icon {
    font-size: 20px;
    width: 24px;
    height: 24px;
  }

  .dataRelation-complete {
    width: 150px;
    display: flex;
    align-items: center;
  }

  .ant-form-item-label:first-of-type {
    flex-basis: fit-content !important;
  }

  .dataRelation-add-btn {
    display: flex;
    gap: 2px;
    padding: 4px 15px 4px 15px;
    justify-content: center;
    align-items: center;
    padding: 4px 15px 4px 15px;
    background-color: #d32210;
    border-color: #d32210;
    color: white;
    border-radius: 5px;
  }
  .dataRelation-add-btn:hover {
    opacity: 0.8;
  }
}

.input-help-icon {
  display: flex;
  .dataRelation-info-icon {
    font-size: 18px;
  }
}

.dataRelation-help-icon {
  font-size: 20px;
}

.dataRelation-exclamation-icon {
  color: #faad14;
  margin-top: 8px;
  margin-right: 14px;
  font-size: 28px;
  font-weight: 300;
}

@primary-color: #03729F;