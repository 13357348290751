.protable-permission {
  .permission-pro-table-search-filter-text {
    font-weight: bold;
    padding-top: 16px;
    margin-left: 24px;
  }

  .ant-form-item-label {
    flex-basis: fit-content !important;
  }

  .permission-action-icon {
    font-size: 20px;
    width: 24px;
    height: 24px;
  }
}

@primary-color: #03729F;