@import '../../../../globalVariables.less';

.help-popover-title {
  font-size: 20px;
  margin: 0;
}

.help-popover-text {
  font-size: 13px;
  margin: 0;
}

.help-popover-overlay {
  width: 100%;
  max-width: 910px;

  @media screen and (max-width: 640px) {
    top: 45px !important;
    max-width: 95vw;
    left: 0 !important;
    right: 0 !important;
    margin-left: auto;
    margin-right: auto;
  }
  .ant-popover-content {
    .ant-popover-inner {
      border-radius: 21px;
      height: 100%;

      @media screen and (max-width: 640px) {
        overflow: auto;
        overflow-x: hidden;
        max-height: 75vh;
      }
      .ant-popover-inner-content {
        padding: 0;
      }
      scrollbar-width: auto;
      scrollbar-color: @SoftPink transparent;
    }
    /* Chrome, Edge, and Safari */
    .ant-popover-inner::-webkit-scrollbar {
      width: 5px;
      margin-left: 19px;
    }

    .ant-popover-inner::-webkit-scrollbar-track {
      background: white;
    }

    .ant-popover-inner::-webkit-scrollbar-thumb {
      background-color: @SoftPink;
      border-radius: 15px;
      border: none;
    }
  }
  Button {
    text-align: center;
    width: 100%;
    margin-bottom: 1vh;
    font-weight: bold;
  }
}

.help-button {
  display: flex !important;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  user-select: none;
  height: 30%;
  text-align: center;
  .icon-help {
    font-size: 18px;
  }
}

.help-popover {
  .help {
    display: flex;
    flex-direction: column;
    padding: 20px 15px 15px;
    gap: 15px;
    .help__header {
      display: flex;
      flex-direction: column;
      gap: 6px;

      &--title {
        .help-popover-title;
      }
      &--description {
        .help-popover-text;
      }
    }

    &__cards-container {
      display: flex;
      flex-direction: column;
      gap: 15px;

      @media screen and (min-width: 640px) {
        flex-direction: row;
      }
      &__card {
        border-radius: 13px;
        display: flex;
        height: 100%;
        width: 100%;

        @media screen and (min-width: 640px) {
          width: 33%;
        }
        .ant-card-body {
          height: 250px;
          padding: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        &--body {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-bottom: auto;

          & h3 {
            .help-popover-title;
          }
          & p {
            .help-popover-text;
          }
        }

        &--cta {
          max-width: 200px;
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 10px;
          justify-content: center;
          margin-top: auto;

          button {
            margin: 0;
          }
        }
      }
    }
  }
}

@primary-color: #03729F;