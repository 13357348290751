.container-form-receipt-header {
  background-color: #f0f2f5;
  padding: 24px;
  padding-right: 0;
  padding-top: 0;

  color: #000000;
  label {
    font-weight: 400 !important;
  }
  .Form-receipt-header {
    padding-right: 2px;

    .ant-collapse-item
      > .ant-collapse-header
      > .ant-collapse-expand-icon
      > .anticon.anticon-right.ant-collapse-arrow
      > svg {
      transform: rotate(90deg) !important;
    }

    .ant-collapse-item-active
      > .ant-collapse-header
      > .ant-collapse-expand-icon
      > .anticon.anticon-right.ant-collapse-arrow
      > svg {
      transform: rotate(-90deg) !important;
    }
  }

  .receipt-config-btn {
    display: none;
  }

  .ant-divider-horizontal {
    margin-bottom: 1em;
  }

  .ant-col.ant-form-item-label {
    padding: 0 0 2px;
  }
}
.container-form-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.receipt-status-info {
  background: rgba(189, 120, 170, 0.2);
  border-radius: 50px;
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: center;
  margin-top: 5px;
}
.receipt-status-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  letter-spacing: 0.07em;
  color: #bd78aa;
}

.receipt-submit-btn {
  float: right;
  background-color: #d32210 !important;
  color: white !important;
  border-radius: 5px !important;
  border-color: transparent !important;
}

.receipt-submit-btn:hover {
  opacity: 0.8;
}

.receipt-align-action-btns {
  align-items: center;
  margin-bottom: 16px;
  height: 35px;
  align-items: center;
}
.buttons-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
  flex-wrap: wrap;
}
@media (max-width: 1290px) {
  .buttons-container {
    flex-direction: column;
    gap: 8px;
  }
}
@media (max-width: 640px) {
  .buttons-container {
    flex-direction: row;
  }
}
.receipt-back-link {
  background: transparent;
  color: #03729f;
  padding-left: 0;
  display: flex;
  align-items: center;
  height: 32px;
}
.review-receipt-action-btn {
  float: right;
  width: 75%;
  min-width: 230px;
  .review-receipt-action-btn-left {
    float: left;
  }
  .review-receipt-action-btn-right {
    float: right;
  }
}
.receipt-back-float-btn {
  float: left;
  width: 25%;
  min-width: 75px;
  padding: 0;
  height: 32px;
  .receipt-back-flex-btn {
    display: flex;
    align-items: center;
  }
}
.ant-collapse.ant-collapse-icon-position-end.ant-collapse-borderless.collapse-receipt,
.ant-collapse.ant-collapse-icon-position-start.ant-collapse-borderless.collapse-receipt {
  .ant-collapse-item {
    .ant-collapse-header {
      .ant-collapse-header-text {
        font-weight: 700;
        padding-left: 16px;
        line-height: 17.73px;
        font-size: 13px;
      }
    }
  }
  .ant-pro-table {
    padding-bottom: 0;
  }
  .ant-table-cell-scrollbar {
    display: none;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
  .add-receipt-detail {
    border: none;
    padding: 0;
    height: auto;
    color: rgba(3, 114, 159, 1);
    margin-top: 22px;
    background: transparent;
    box-shadow: none;
  }
  z-index: 1;
  max-height: 100% !important;
}

section.ant-layout[style*='margin-left: 200px'] .container-flex {
  width: calc(100% - 275px);
  position: relative;
  left: -19px;
}

.Form-receipt-header .collapse-receipt {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  .ant-collapse-header {
    padding-left: 0;
  }

  .ant-collapse-item {
    margin-bottom: 8px;
  }

  .ant-collapse-item.ant-collapse-item-active {
    padding-bottom: 16px;
  }
}

.react-joyride__tooltip {
  padding: 12px;
}

.ant-collapse-content.ant-collapse-content-active,
.ant-collapse-content.ant-collapse-content-inactive {
  padding: 0 16px 0px 24px;
}

section.ant-layout[style*='margin-left: 0px'] .container-flex {
  width: calc(100% - 75px);
  position: relative;
  left: -18px;
}
section.ant-layout .container-flex .collapse-receipt {
  background: #ffffff;
  display: block;
  position: fixed;
  bottom: 0;
  width: inherit;
  box-shadow: -2px 2px 5px 1px #0000005c;
}

.container-flex {
  .ant-collapse-item
    > .ant-collapse-header
    > .ant-collapse-expand-icon
    > .anticon.anticon-right.ant-collapse-arrow
    > svg {
    transform: rotate(-90deg) !important;
  }

  .ant-collapse-item-active
    > .ant-collapse-header
    > .ant-collapse-expand-icon
    > .anticon.anticon-right.ant-collapse-arrow
    > svg {
    transform: rotate(90deg) !important;
  }
}

.input-note {
  font-size: 10px;
  color: gray;
  margin-top: 5px;
}

.input-info {
  margin-bottom: 4px;
  margin-top: 16px;
}

.FormItem-with-info {
  margin-bottom: 0px !important;
}

.icon-info {
  align-items: center;
  display: flex;
  font-size: 20px;
  text-align: left;
  color: #03729f;
}

.message-multiselect-info {
  font-size: 13px;
  margin-bottom: 0;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  padding-left: 8px;
}

.message-info {
  background: rgba(204, 191, 255, 0.2);
  border: 1px solid rgba(204, 191, 255, 0.5);
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 22px;
}

.FormReview {
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}

.review-receipt-col-left {
  float: left;
  width: 29%;
  .container-info {
    display: flex;
    flex-direction: row;
    padding: 8px 16px;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    .container-msj {
      display: flex;
      flex-wrap: nowrap;
    }
    .container-btn {
      .btn-start-tour {
        font-size: 13px;
      }
    }
  }
}

.review-receipt-col-right {
  float: right;
  width: 68%;
  margin-left: 2%;
}

@media (max-width: 640px) {
  .review-receipt-col-right {
    width: 100%;
    margin-left: 0px;
    padding-right: 2px;
  }
}

.review-receipt-tour-modal {
  width: 727.26px !important;
  .ant-modal-body {
    padding: 32px;
  }
  .review-receipt-tour-modal-content {
    display: flex;
    flex-direction: row;
    gap: 32px;
  }
  .review-receipt-tour-modal-description {
    flex: 1;
    .review-receipt-tour-modal-title {
      color: rgba(3, 114, 159, 1);
      margin-bottom: 16px;
    }
    p {
      font-size: 13px;
      font-weight: 400;
      line-height: 17.73px;
      text-align: left;
      margin-bottom: 16px;
    }
  }
  .review-receipt-tour-btn-gruop {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 32px;
  }
  .review-receipt-tour-btn {
    font-size: 13px;
    font-weight: 400;
    line-height: 17.73px;
  }
}

@media screen and (max-width: 40rem) {
  .review-receipt-col-left {
    width: 100%;
  }
}

.receipt-exclamation-icon {
  color: #ffb156;
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0;
}

.modal-confirmation-check-title {
  font-size: 16px;
}

.modal-confirmation-check-paragraph {
  margin-bottom: 0;
}

@primary-color: #03729F;