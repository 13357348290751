.automation-control {
  margin-bottom: 15px;

  &__title {
    font-size: 16px;
    font-weight: 700;
  }

  &__text {
    font-size: 13px;
  }

  &__radio-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .ant-radio-inner {
    width: 20px;
    height: 20px;
    border: 2px solid black;

    &::after {
      transform: scale(0.6);
    }
  }
}

@primary-color: #03729F;