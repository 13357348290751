@import "~antd/lib/style/themes/default.less";
@sideMenuIconColor: rgba(255, 255, 255, 0.65);
@sideMenuIconColorHover: #ffffff;
.menuTrigger {
  left: 16px;
  top: 9px;
  z-index: 10;
  color: white;
  position: absolute;
  line-height: 64px;
  margin: 0 @margin-lg;
  transition: all 0.2s;
  > .trigger {
    cursor: pointer;
    transition: color 0.3s;
    color: @sideMenuIconColor;
  }
  > .trigger:hover {
    color: @sideMenuIconColorHover;
  }
}

@primary-color: #03729F;