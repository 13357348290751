.details-render {
  .details-render__total {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
    b {
      font-size: 1rem;
    }
  }

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding: 20px;
}

@primary-color: #03729F;