.credits-section {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__label {
        margin: 0;
      }
    }
  }
}

@primary-color: #03729F;