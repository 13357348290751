.receipt-detail-thead {
  .ant-table-body {
    overflow-y: auto !important;
  }
  .ant-table-cell-fix-right-first {
    right: 0 !important;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent;
  }
  .receipt-detail-action-icon {
    font-size: 20px;
    width: 24px;
    height: 24px;
  }
}

.receipt-detail-saveForm {
  .ant-modal-header {
    padding: 16px;
    .ant-modal-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 21.82px;
    }
  }
  .ant-modal-body {
    padding: 22px 16px;
  }
  .ant-col.ant-form-item-label {
    padding: 0 0 2px;
  }
}

.collapse-receipt-item {
  background-color: white;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.05);
  border-color: rgba(238, 238, 238, 1);
  border-width: 1px;
  border-radius: 5px;
  margin-bottom: 12px;
  .ant-collapse-item-active {
    padding-bottom: 22px;
  }
}
.collapse-receipt {
  .ant-collapse-expand-icon {
    line-height: 17.73px;
  }
}

.collapse-receipt-item-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .collapse-receipt-item-title-header {
    font-weight: 400;
  }
}

.add-receipt-detail{
  display: flex;
  .receipt-detail-add-icon{
    font-size: 22px;
  }
}

.receipt-item-exclamation-icon {
  color: #faad14;
  margin-top: 8px;
  margin-right: 14px;
  font-size: 28px;
  font-weight: 300;
}
@primary-color: #03729F;