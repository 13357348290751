@import '~antd/es/style/themes/default.less';

.SaveForm {
  form {
    .ant-input-number,
    .ant-picker {
      width: 100% !important;
    }
    .ant-form-item-explain {
      margin-top: 2px;
    }
  }
}
.SaveFormFooter {
  display: flex;
  justify-content: flex-end;

  .Reset,
  .Cancel {
    margin-right: 10px;
  }
  .ant-btn {
    height: 34px;
    font-size: 14px;
    .anticon {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 40rem) {
  .containerSaveForm {
    margin-bottom: 100px;
  }
}

@primary-color: #03729F;