.main-card-generate2FA__container {
  .main-card-generate2FA {
    max-width: 90vw;
    width: 404px;
    margin: 0 auto;
    border: 1px solid #eee;
    border-radius: 10px;
    box-shadow: 5px 5px 30px rgb(0 0 0 / 5%);
    text-align: left;
    margin-bottom: 20px;
    .ant-card-head {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border: 2px solid #eee;
      background-color: #fff;
      max-height: 47px;
      display: flex;
      align-items: center;
      padding: 16px;
      .ant-card-head-title {
        white-space: unset;
        font-size: 20px;
        padding: 0;
        color: rgba(0, 0, 0, 0.7);
        font-weight: 400;
        letter-spacing: -0.5px;
      }
    }
    .ant-card-body {
      padding: 16px;
      .container-qr-image {
        text-align: center;
      }
    }

    .container-buttons {
      display: flex;
      align-items: center;
      margin-top: 70px;
      justify-content: flex-end;
      width: 100%;
      button.ant-btn-default {
        margin-right: 10px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .container-button {
      display: flex;
      align-items: center;
      margin-top: 20px;
      justify-content: flex-end;
      width: 100%;
    }

    .main-card-generate2FA__qr-container {
      width: 100%;
      margin: 30px 0;
    }
    .main-card-generate2FA__button {
      margin-top: 15px;
      border-radius: 5px;
      border-color: transparent;
      background-color: #d32210;
      color:white;
    }

    .main-card-generate2FA__button:hover {
      opacity: 0.7;
    }
    @media screen and (max-width: 768px) {
      width: 85vw;
    }
  }

  .generate-qr-btn {
    border-radius: 5px;
    border-color: transparent;
    background-color: #d32210;
    color:white;
  }
  .generate-qr-btn:hover {
    opacity: 0.7;
  }


}

@media screen and (max-width: 40rem) {
  .main-card-generate2FA {
    .ant-card-head-title {
      font-size: 16px !important;
    }
  }
}

@primary-color: #03729F;