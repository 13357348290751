.container-message-tenant-not-selected {
  text-align: center;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  &__message-tenant-not-selected {
    letter-spacing: 0.07em;
    font-size: 14px;
    font-weight: 300;
  }
}

@primary-color: #03729F;