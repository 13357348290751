.protable-concept-rule {
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent !important;
  }

  .conceptRule-action-icon{
    font-size: 20px;
    width: 24px;
    height: 24px;
  }

  .modal-concept-rule-info {
    width: 700px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-right: 42px;
  }

  .modal-concept-rule-info-text {
    display: flex;
    flex-direction: column;
    .modal-concept-rule-info-text-description {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 13px;
      line-height: 17.73px;
    }
  }

  .modal-concept-rule-info-icon {
    display: flex;
    margin-right: 26px;
    font-size: 20px;
  }

  .ant-pro-table-list-toolbar-left {
    display: none;
  }

  .ant-space.ant-space-horizontal.ant-space-align-center.ant-pro-table-list-toolbar-right {
    width: 100%;
    > .ant-space-item {
      width: 100%;
    }
  }

  .ant-space.ant-space-horizontal.ant-space-align-center {
    width: 100% !important;
    justify-content: space-between !important;
    flex-wrap: wrap;
  }

  .ant-table-expanded-row-fixed {
    background-color: #ffffff !important;
    padding: 20px 17px;
  }
}

.modal-tag-rule {
  .modal-concept-rule-tag-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  .modal-concept-rule-info-tag-text {
    display: flex;
  }

  .modal-concept-rule-info-tag-icon {
    display: flex;
    margin-right: 8px;
    font-size: 20px;
  }
}

.protable-concept-rule--wrapper {
  padding: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 20px 0px #0000001a;
  .protable-concept-rule--wrapper__title {
    color: #004662;
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  .protable-concept-rule--wrapper__description {
    margin-bottom: 1rem;
  }
  .protable-concept-rule--wrapper2 {
    border: 1px solid #eeeeee;
    border-radius: 10px;
    .protable-concept {
      padding: 1rem;
      .ant-pro-table-search-query-filter {
        margin-bottom: 0.5rem;
      }
      .ant-pro-query-filter {
        box-shadow: 0px 4px 20px 0px #0000001a;
        border-radius: 10px;
        .name-input {
          border-radius: 5px;
        }
      }
      .ant-pro-card-body {
        border: 1px solid #eeeeee;
        padding: 0px;
        box-shadow: 0px 4px 20px 0px #0000001a;
        .ant-table-thead
          > tr
          > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
          background-color: transparent;
        }
        .ant-table.ant-table-middle {
          .ant-table-thead > tr > th,
          .ant-table-tbody > tr > td {
            padding: 0.75rem 2rem;
          }
        }
      }
      .ant-switch-checked {
        background-color: #3baa61;
      }
      .ant-form-item {
        margin: 0;
      }
      .ant-select-selection-item {
        text-align: left;
      }
    }
  }

  .ant-select-selector {
    border-radius: 5px !important;
    border-color: rgba(35, 35, 35, 0.15) !important;
    border-width: 1px !important;
  }

  .content-search-table {
    padding-right: 0px;
  }

  .ant-input-affix-wrapper {
    border-radius: 5px;
  }

  .concepts-pro-table-search-filter-text {
    font-weight: bold;
    padding-top: 16px;
    margin-left: 24px;
  }

  .ant-form-item-label {
    flex-basis: fit-content !important;
  }

  .buttonChangeProTableView {
    background-color: #d32210 !important;
    color: white !important;
    border-color: transparent;
  }
  .buttonChangeProTableView:hover {
    opacity: 0.8;
    border-color: transparent;
  }
}

.modal-concept {
  width: 362px !important;
  .ant-modal-content {
    padding: 16px 16px 0 16px;
    .SaveFormFooter {
      margin-top: 32px;
    }
    .ant-modal-header {
      padding: 0 0 16px 0;
      .ant-model-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 21.82px;
      }
    }
    .ant-modal-body {
      padding: 0;
      .ant-pro-card.ant-pro-table-search.ant-pro-table-form.ant-pro-table-search-form {
        padding: 16px 0;
        .ant-select-selector,
        input {
          border-radius: 5px;
        }

        .ant-form-item {
          margin-bottom: 16px;

          .ant-col.ant-form-item-label {
            padding: 0 0 4px;
            > label {
              font-size: 13px;
              font-weight: 400;
              line-height: 17.73px;
            }
          }
        }

        .modal-concept-info {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
        }

        .modal-concept-info-text {
          display: flex;
          flex-direction: column;
          .modal-concept-info-text-description {
            margin-bottom: 0;
            font-weight: 400;
            font-size: 13px;
            line-height: 17.73px;
          }
        }

        .modal-concept-info-icon {
          display: flex;
          margin-right: 16px;
          font-size: 20px;
        }
      }
    }
  }
}

@primary-color: #03729F;