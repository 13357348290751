.gray-bg {
  background-color: rgba(238, 238, 238, 1);
}

.protable-concept-rule-subtable {
  .ant-table.ant-table-middle {
    margin: 0 !important;
  }

  .add-cuit {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .receipt-back-link {
    display: flex;
    align-items: center;
    background: transparent;
    color: #03729f;
  }

  .ant-switch-checked {
    background-color: #3baa61;
  }

  .subtable-title-col{
    font-size: 13px;
    line-height: 17.73px;
    .subtable-title{
        font-weight: 700;
        margin-bottom: 4px;
      }
    .subtable-title-description{
        font-weight: 400;
        margin-bottom: 0;
      }
  }
  
}

.conceptRule-add-icon{
  font-size: 22px;
}
@primary-color: #03729F;