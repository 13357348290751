.custom-table {
  &__icon {
    transition: transform 0.24s;
    margin-right: 5px;
    pointer-events: none;

    &--active {
      transform: rotate(90deg);
    }
  }

  .ant-table {
    font-size: 13px;

    .ant-table-thead {
      > tr > th {
        font-weight: 600;
        &::before {
          visibility: hidden;
        }
      }
    }
  }

  .ant-table-cell {
    padding-right: 8px;
    padding-left: 8px;
  }

  .ant-table-content {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .ant-table-tbody {
    border: 1px solid #f0f0f0;
  }

  .ant-table-row td:first-child {
    border-left: 1px solid #f0f0f0;
  }

  .ant-table-row td:last-child {
    border-right: 1px solid #f0f0f0;
  }

  tr {
    td:first-child {
      padding-left: 20px;
    }
  }

  tr {
    th:first-child {
      padding-left: 20px;
    }
  }
}

@primary-color: #03729F;