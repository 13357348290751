@supportIconColor: #03729f;
@supportBorderContainerColor: #03729f;
@supportBackgroundContainerColor: #f5f2ff;
@iconApprovedColor: #3baa61;
@iconCancelColor: #e25b5b;
@iconWarningColor: #ffb156;
@iconCheckColor: #01b3ff;
@submitButtonColor: #d32210;
@submitButtonFontColor: #FFFFFF;
@titleAndPriceColor: #262b47;
@informativeTextColor: #1C1B1F;

.PacksPage-container {
  .GenericCard-container {
    padding: 8px 16px 8px 8px;
    .GenericCard-container--information {
      gap: 10px;
    }
  }
  .AlertCard-container {
    padding: 8px 16px 8px 8px;
    .AlertCard-container--information {
      gap: 10px;
    }
    .ant-btn {
      margin-right: 0;
    }
  }
  display: flex;
  flex-direction: column;
  gap: 8px;
  .CardToSelect {
    gap: 0;
    &--price {
      margin-bottom: 16px;
      font-size: 50px;
      font-weight: 700;
      line-height: 68.2px;
      color: @titleAndPriceColor;
    }
    &--pack {
      font-size: 30px;
      font-weight: 400;
      line-height: 40.92px;
      color: @titleAndPriceColor;
    }
    &--description {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
    }
    &__flex {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 8px;
      &--quantity,
      &--days {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.07em;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.1px;
      }
    }
  }
  .PacksPage-message-container {
    padding: 8px 16px 8px 8px;
    border: 1px solid @supportBorderContainerColor;
    background-color: @supportBackgroundContainerColor;
    .packsPage-support-icon {
      font-size: 44px;
      color: @supportIconColor;
    }
    .GenericCard-container--information {
      gap: 10px;
    }
    .ant-btn {
      margin-right: 0;
    }
  }

  .packsPage-approve-icon {
    font-size: 44px;
    color: @iconApprovedColor;
  }
  .packsPage-cancel-icon {
    font-size: 44px;
    color: @iconCancelColor;
  }
  .packsPage-warning-icon {
    font-size: 44px;
    color: @iconWarningColor;
  }
  .packsPage-check-icon {
    font-size: 22px;
    color: @iconCheckColor;
  }

  .CardsContainer {
    .CardToSelect {
      .ant-btn {
        align-self: flex-start;
        background-color: @submitButtonColor !important;
        color: @submitButtonFontColor !important;
        border-radius: 5px;
        border-color: transparent !important;
      }
      .ant-btn:hover {
        opacity: 0.8;
      }
    }
  }
  .CardToSelect--pack {
    font-size: 30px;
    font-weight: 400;
    line-height: 40.92px;
    color: @titleAndPriceColor;
  }
  .cardToSelect--include {
    font-size: 14px;
    font-weight: 700;
    line-height: 19.1px;
    margin-bottom: 8px;
  }

  .ant-card-bordered {
    width: 350px;
  }

  .informative-text{
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 17.73px;
    color:@informativeTextColor;
  }

  .cardToSelectDivider{
    margin: 16px 0; 
  }
}

@primary-color: #03729F;