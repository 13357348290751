.Login {
  display: flex;
  flex-direction: column;
  align-items: center;
  .login-form {
    max-width: 300px !important;
    margin: auto !important;
    width: 100%;
    .ant-row {
      margin-bottom: initial !important;
    }

    .ant-form-item-explain {
      margin: 5px initial !important;
    }
    > .ant-form-item {
      margin-bottom: 16px;
      > .ant-row.ant-form-item-row {
        > .ant-col.ant-form-item-control {
          > .ant-form-item-control-input {
            > .ant-form-item-control-input-content {
              input:-webkit-autofill,
              input:-internal-autofill-selected,
              input:-webkit-autofill:hover,
              input:-webkit-autofill:focus,
              input:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
                box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
              }
              > .ant-input-affix-wrapper {
                border-radius: 5px;
                background-color: transparent;
                font-weight: 400;
                padding: 4px 11px;
              }
            }
          }
        }
      }
    }

    .login-form-forgot {
      float: right !important;
      color: black;
      margin-bottom: 16px;
    }
    .login-divider{
      border-width: 1px;
    }
    .login-form-register {
      text-align: center;
    }

    .login-form-button {
      width: 100% !important;
      border-radius: 5px;
      background-color: #d32210 !important;
      border-color: transparent !important;
    }
    .login-form-button:hover {
      opacity: 0.8;
    }
    .ant-row.ant-form-item {
      height: 65px;
    }
  }
  .container-buttons-login {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
    max-width: 300px;
  }

  .braulio-version{
    margin-top:10px;
    margin-bottom:16px;
  }

  .logIn-errorMsj{
    text-align: left;
  }

  .login-title{
    font-size: 30px;
    font-weight: 700;
    line-height: 40.92px;
  }
}

.site-form-item-icon {
  position: relative
}
@primary-color: #03729F;