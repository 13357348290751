.ant-descriptions {
  .ant-descriptions-header {
    margin-bottom: 8px;
  }
  .ant-descriptions-view {
    tbody {
      display: block;
      tr {
        display: flex;
        th {
          width: min(50%, 350px);
        }
      }
    }
  }
  margin-bottom: 16px;
}

@primary-color: #03729F;