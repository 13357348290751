.configure-integration-modal {
  .configure-integration-modal-loading-overlay {
    min-height: 200px;
  }
  .finish-completed_title {
    display: flex;
    gap: 2px;
    .finish-Completed-icon {
      font-size: 18px;
    }
    .anticon {
      padding-left: 12px;
      font-size: 16px;
    }
  }

  .ant-switch-checked {
    background-color: #3baa61;
  }
  .url-api-link {
    display: flex;
    .url-api-link-button {
      border: none;
      box-shadow: none;
      display: flex;
      align-items: center;
    }
    .url-api-link-icon {
      font-size: 22px;
      padding-left: 11px;
    }
  }
}

@primary-color: #03729F;