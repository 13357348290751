.complete-register-page {
  display: flex;
  justify-content: center;
  max-width: 300px;
  margin: auto;
  > .complete-register-page__container-form {
    max-width: 300px;
    p {
      text-align: center;
    }
    > .ant-form.ant-form-horizontal {
      > .ant-form-item {
        margin-bottom: 16px;
        > .ant-row.ant-form-item-row {
          > .ant-col.ant-form-item-control {
            > .ant-form-item-control-input {
              > .ant-form-item-control-input-content {
                > .ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-has-feedback {
                  background-color: transparent;
                  border-radius: 5px;
                  > input {
                    background-color: transparent;
                  }
                  input:-webkit-autofill,
                  input:-internal-autofill-selected,
                  input:-webkit-autofill:hover,
                  input:-webkit-autofill:focus,
                  input:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0)
                      inset;
                    box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0) inset;
                  }
                }
              }
            }
          }
        }
      }
      > .complete-register-page__container-form__password_requirements {
        text-align: left;
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 38px;
      }
    }
  }
  > .complete-register-page__container-token-is-expired {

    > .complete-register-page__container-token-is-expired__container-buttons {
      display: flex;
      flex-direction: column;
      
      > .complete-register-page__container-token-is-expired__container-buttons__button-go-to-login {
        color: black;
        font-weight: bold;
        
      }
    }
  }

  .complete-register-page__container-token-is-expired__container-buttons__button-resend-activation-link{
    width: 100% !important;
    border-radius: 5px;
    background-color: #d32210 !important;
    border-color: transparent !important;
    color:white;
  }

  .complete-register-page__container-token-is-expired__container-buttons__button-resend-activation-link:hover{
    opacity: 0.8;
  }

  .btn-completeRegister{
    width: 100% !important;
      border-radius: 5px;
      background-color: #d32210 !important;
      border-color: transparent !important;
  }

  .btn-completeRegister:hover {
    opacity: 0.8;
  }

  .complete-register-page-errorMsj{
    text-align: left;
  }

  .complete-register-page__container-form__password{
    text-align: center;
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 38px;
  }
  .complete-register-page__container-form__title{
    font-size: 30px;
    font-weight: 700;
    line-height: 40.92px;
  }
}

@primary-color: #03729F;