.PublicLayoutContent {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 38px;
}

.PublicLayoutTitle {
  width: 140px;
  height: 34px;
  margin-bottom: 32px;
}
.PublicLayoutHeader{
  margin: auto;
  margin-bottom:0;
}
.PublicLayout {
  height: 100%;
  overflow: auto;
}

.leftSide {
  display: flex;
  background-color: #262b47;

  flex-direction: column;

  @media screen and (max-width: 40rem) {
    order: 2;
    height: 100vh;
  }
  .braulio-settings-info{
    margin-bottom:0;
    color:rgba(255, 255, 255, 0.9);
  }
}

.rightSide {
  @media screen and (max-width: 40rem) {
    width: 100%;
  }
}

.index-row {
  text-align: center;
}

.icon-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.text-box {
  display: flex;
  padding-left: 10px;
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
  flex-wrap: wrap;
  .title-box {
    font-size: 24px;
    color: white;
    margin: 0;
  }
}

.features-container {
  max-width: 371px;
  margin: auto;
  @media screen and (max-width: 40rem) {
    flex-direction: column;
  }
  .features-container_row {
    display: flex;
    flex-direction: row;
    margin-bottom: 44px;
    .features-container_item {
      margin: 0px 30px 0px 0px;
      min-width: 50%;
      @media screen and (max-width: 40rem) {
        min-width: 46%;
      }
      display: flex;
      align-items: center;
      &:last-child {
        margin: 0px;
      }
      .features-container_icon {
        margin-right: 8px;
      }
      .features-container_description {
        text-align: left;
        color: rgba(255, 255, 255, 0.9);
        h2 {
          margin-bottom: 8px;
          font-weight: 700;
          color: rgba(255, 255, 255, 0.9);
        }
        p {
          margin-bottom: 0;
        }
      }
    }
    &:last-child {
      margin: 0px;
    }
  }
}

.braulio-empresas_banner {
  max-width: 535px;
  display: flex;
  color: rgba(255, 255, 255, 0.9);
  text-align: left;
  padding: 0 16px;
  margin: auto;
  margin-top:0;
  align-items: center;
  p:last-child {
    margin-bottom: 0;
  }
  .braulio-empresas_banner-text {
    margin-right: 15px;
    max-width: 425px;
  }

  .img-braulio-empresa{
    width: 68.35px;
    height: 96.97px;
  }
}

@primary-color: #03729F;