.toolbar-filter-order {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: start;
  width: 100%;

  &__container-filter-order-principal-filter-operations {
    &__container-search {
      .ant-form-item {
        margin-bottom: 0 !important;
      }
    }
  }

  &__container-button-refresh {
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
    }
  }
}

.button-new {
  justify-content: end;
  display: flex;
  margin-left: auto;
}

.drawer-filter {
  &__buttons {
    display: flex;
    gap: 8px;
  }
}

.toolbar-filter-order__left {
  display: flex;
  align-items: center;
}

.toolbar-filter-order__right {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  width: auto;
  flex-grow: 1;
}

.toolbar-filter-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0px;
}

.filter-button {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 8px !important;
  transition: color 0.3s ease !important;
}

.filter-button:hover {
  background: transparent;
  color: #03729f;
}

@primary-color: #03729F;