.state-tab-container {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow-x: scroll;
  margin-bottom: 10px;
  &::-webkit-scrollbar {
    display: none;
  }

  .state-tab {
    margin-top: 1.25rem;
    white-space: nowrap;
    padding: 5px 10px;
    box-sizing: border-box;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition-property: color, border-bottom;
    transition-duration: 0.5s;
    &.selected {
      color: @primary-color;
      border-bottom: 2px solid @primary-color;
    }
    &:hover:not(.selected) {
      border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    }
  }
}
.btn-icon-subtable {
  font-size: 12px;
  padding-left: 4px;
  padding-bottom: 9px;
}
.ant-table-wrapper {
  .anticon.pointer + .anticon.pointer {
    margin-left: 10px;
  }
}

.ant-table-content {
  .highlighted-row {
    background-color: #fff7ee;
    .ant-table-cell-fix-right {
      background-color: #fff7ee;
    }
  }
}

.receipt-selector-action {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.07em;
  color: #03729f;
  cursor: pointer;
  margin-right: 10px;
}

.receipt-selector-info {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
}

.ant-table-expanded-row .ant-table-expanded-row-fixed {
  background-color: rgb(209, 225, 255);
  .container-expandable {
    background-color: white;
    .container-header {
      padding: 16px 9px;
      font-weight: 600;
    }
  }
}

.ReceiptLogs {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
  .ant-pro-table {
    padding-bottom: 0;
  }
}

.errorMessage {
  margin-top: 10px;
  color: #d32210;
}

.receipt-btn-container {
  .receipt-action-btn {
    width: 24px;
  }
  .ant-btn-icon-only {
    width: 24px;
  }
}

.export-dropdown-button {
  width: 100%;
}
.upload-container {
  display: flex;
  background-color: #ffffff;
  height: 5.125rem;
  padding: 0.625rem;
  border-radius: 0.625rem;
  box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.1);
  gap: 0.8125rem;
  > span {
    flex: 1;
  }
  .AvailableQuotaCard-container {
    max-width: 13rem;
    min-height: 3.75rem;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
  }
}
.protable-receipt {
  .sender-column {
    max-width: 17vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .receipt-pro-table-search {
    .ant-btn-loading-icon {
      display: none;
    }
    .ant-btn-loading {
      &::before {
        all: unset;
      }
    }
  }
  .receipt-pro-table-search-filter-text {
    font-weight: bold;
    padding-top: 16px;
    margin-left: 24px;
  }
  .ant-form-item-label {
    flex-basis: fit-content !important;
  }
  .ant-form.ant-form-horizontal.ant-pro-query-filter.receipt-pro-table-search.ant-pro-form {
    padding-top: 10px;
  }
  .ant-space.ant-space-horizontal.ant-space-align-center.ant-pro-table-list-toolbar-right {
    flex: 1;
    .ant-space-item:nth-child(1) {
      width: 100%;
      .ant-space.ant-space-horizontal.ant-space-align-center {
        width: 100%;
      }
    }
  }

  .ant-space.ant-space-horizontal.ant-space-align-center {
    .ant-space-item:nth-child(1) {
      flex: 1;
    }
  }
}

.ant-alert-info {
  background-color: #f4f4f4;
  border-color: #c0c0c0;
}

.page-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.modal-duplicated-invoice {
  .ant-modal-confirm-title p {
    margin-bottom: 8px;
  }
  .ant-modal-body {
    padding: 16px;
  }
  .modal-font {
    font-size: 13px;
    font-weight: 400;
    line-height: 17.73px;
  }

  .ant-modal-confirm-btns {
    margin-top: 0 !important;
  }
  .modal-description-loadead {
    margin-bottom: 8px;
  }
  .modal-description-filename {
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 34px;
  }

  .modal-description-ask-reload {
    margin-bottom: 34px;
  }
}

.btn-refresh,
.btn-refresh:hover {
  display: flex;
  background: #d32210 !important;
  color: white !important;
  border-color: transparent !important;
  .refresh-icon {
    font-size: 22px;
    font-weight: 200;
  }
}

.btn-refresh:hover {
  opacity: 0.8;
}

.action-menu-icon {
  font-size: 18px;
}

.bg-pulse {
  animation: pulse 1.2s ease infinite;
  background: #f7776a;
  border-radius: 5px;
  width: 114px;
  height: 32px;
  position: absolute;
}

.receipt-exclamation-icon {
  color: #faad14;
  margin-top: 8px;
  margin-right: 14px;
  font-size: 28px;
  font-weight: 300;
}

.modal-discard-title {
  font-size: 16px;
  line-height: 21.8px;
  margin-bottom: 8px;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.ant-pro-table-list-toolbar-right {
  > .ant-space-item > .ant-space {
    > .ant-space-item:has(.receipt-selector-info) {
      flex-grow: unset !important;
      flex-basis: content !important;
    }
  }
}

@primary-color: #03729F;