.ReprocessCard-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  gap: 20px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 10px;
  &--information {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    &--title {
      font-weight: 700;
      margin-bottom: 0px;
    }
    &--description {
      margin-bottom: 0px;
    }
  }
  .ant-btn {
    border: 1px solid #03729f;
    border-radius: 5px;
    margin-right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      height: 100%;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@primary-color: #03729F;