.container-flag-buttons {
  display: flex;
}

.profile-icon-item{
  font-size: 18px;
}

.language-button {
  display: none;
}
@primary-color: #03729F;