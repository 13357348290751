@shadowColor: #0000001A;
.CardsContainer {
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  padding-bottom: 16px;
  .information {
    padding-bottom: 0.5rem;
    .information--container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .AvailableQuotaCard-container {
        padding: 0.5rem;
      }
    }
    &--title {
      font-weight: 400;
      font-size: 16px;
      margin: 0px;
      line-height: 21.82px;

    }
    &--description {
      font-weight: 400;
      font-size: 14px;
      margin: 0px;
    }
  }
  .ListOfCardsToSelect {
    padding-top: 8px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
    .ant-card-bordered {
      box-shadow: 0px 10px 30px 0px @shadowColor;
    }
    .ant-card-body{
      padding: 16px !important;
    }    
  }
  .ant-btn.ant-btn-default {
    margin-top: 50px;
    margin-right: 45px;
    border-radius: 5px;
    align-self: flex-end;
  }
}

@primary-color: #03729F;