.filter-tag {
  display: inline-flex;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 0px 4px 8px;
  background-color: #fafafa;
  height: 22px;
  justify-content: center;
  align-items: center;

  &__name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
  &__icon {
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 6px 6px;

    svg {
      height: 7px;
      width: 7px;
    }
  }
}

@primary-color: #03729F;