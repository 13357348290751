.modal-view-receipt {
  .modal-view-receipt--tabs {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        border-radius: 16px 16px 0 0;
        &.ant-tabs-tab-active {
          background-color: @primary-color;
          .ant-tabs-tab-btn {
            color: #fff;
          }
        }
      }
    }
  }
}

@primary-color: #03729F;