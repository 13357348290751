.CardToSelect {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .ant-btn {
    align-self: center;
  }
  .ant-btn-primary[disabled] {
    color: #ffff !important;
    opacity: 0.5;
    background-color: @primary-color;
    border: none;
  }
}

@primary-color: #03729F;