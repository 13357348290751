@iconColor: #03729F;

.MethodsPage-container {
  .ant-card-body {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  display: flex;
  flex-direction: column;
  gap: 8px;
  .has-discount {
    justify-content: center;
  }
  .coming-soon {
    justify-content: center;
  }
  .CardToSelect {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    min-width: 140px;
    &--image {
      display: flex;
      align-items: center;
      height: 4.375rem;
      .ant-image-img {
        max-width: 120px;
      }
    }
  }

  .CardsContainer .ListOfCardsToSelect {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 8px;
    justify-content: flex-start;
  }
}

.MethodsPage-message-container {
  padding: 8px 16px 8px 8px !important;
  .MethodsPage-info-icon{
    font-size: 44px;
    color: @iconColor;
  }
  .GenericCard-container--information {
    gap: 10px;
  }
}
@primary-color: #03729F;