.receipt-taxes-protable{
  .ant-table-body{
    overflow-y: auto !important;
  }
  .ant-table-cell-fix-right-first{
    right: 0 !important;
  }
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent;
  }
  .receipt-taxes-discount-action-icon {
    font-size: 20px;
    width: 24px;
    height: 24px;
  }
}


.receipt-tax-saveForm {
  .ant-modal-header{
    padding: 16px;
    .ant-modal-title{
      font-weight: 400;
      font-size: 16px;
      line-height: 21.82px;
    }
  }
  .ant-modal-body{
    padding:22px 16px
  }
  .ant-form-item:nth-child(0) {
    margin-bottom: 0px;
  }
  .ant-col.ant-form-item-label {
    padding: 0 0 2px;
  }
}

.add-receipt-taxes-discount{
  border: none;
  padding: 0;
  height: auto;
  color: rgba(3, 114, 159, 1);
  margin-top: 22px;
  background: transparent;
  box-shadow: none;
  display: flex;
  .receipt-taxes-discount-add-icon{
    font-size: 22px;
  }
}

.receipt-taxes-exclamation-icon{
  color: #faad14;
  margin-top: 8px;
  margin-right: 14px;
  font-size: 28px;
  font-weight: 300;
}
@primary-color: #03729F;