@import '../../../globalVariables.less';

.billing-form {
  .billing-form__card {
    background-color: white;
    padding: 24px;
    border-radius: 5px;
    box-shadow: @BoxShadow;
    max-width: 90%;
    margin: 0 auto;
    &--title {
      font-weight: 400;
      font-size: 20px;
      margin: 0px;
    }
    &--description {
      font-weight: 400;
      font-size: 14px;
      margin: 0 0 20px;
    }
    .ant-form-item {
      margin-bottom: 10px;
    }
    .ant-btn + .ant-btn {
      margin-left: 10px;
    }
    .ant-btn.ant-btn-default {
      border: 1px solid #03729f;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@primary-color: #03729F;