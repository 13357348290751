@font-face {
  font-family: 'CircularAbstractsBold';
  src: local('CircularAbstractsBold'),
    url('../../assets/fonts/CircularAbstracts.woff');
  font-weight: bold;
}
div.TwoFALayout {
  text-align: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-color: #262b47;
  div.TwoFALayoutTitle {
    min-height: 154px;
    margin: auto;
    display: flex;
    align-items: center;
    img {
      width: 8vw;
      margin-bottom: 5px;
      margin-top: 5px;
      min-width: 165px;
      max-width: 330px;
    }
  }
  .ant-btn-primary[disabled] {
    color: #ffff !important;
    opacity: 0.5;
    background-color: @primary-color;
    border: none;
  }
  div.TwoFALayoutContent {
    flex: 2;
  }
  div.TwoFALayoutFooter {
    flex: 0;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 40rem) {
  div.TwoFALayout {
    div.TwoFALayoutTitle {
      height: 100px;
    }
  }
}

@primary-color: #03729F;