@import '../../../globalVariables.less';

.AvailableQuotaCard-container {
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: @BoxShadow;
  padding: 8px 16px;
  &--info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    &__flex {
      display: flex;
      flex-direction: row;
      grid-gap: 10px;
      gap: 10px;
      justify-content: space-between;
      flex-wrap: nowrap;
      p {
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    span {
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .ant-btn {
      padding-bottom: 10px;
    }
  }
}
@media (max-width: 720px) {
  .AvailableQuotaCard-container {
    width: 80vw;
    height: auto;
  }
}

@primary-color: #03729F;