.review-receipt-col-right {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.canvas-pdf {
  display: grid;
  place-items: center;
  max-height: 100vh !important;
  overflow-y: auto;
}

.canvas-pdf > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.canvas-rect {
  z-index: 10;
}

.canvas-btnGroup {
  display: flex;
  width: 100%;
  gap: 8px;
}

.canvas-prevPage-btn {
  text-align: center;
}

.canvas-nextPage-btn {
  text-align: center;
  height: max-content;
  margin-left: auto;
  margin-top: 8px;
}

.btn-group-pageinfo__text {
  text-wrap: nowrap;
}

.btn-group-pageinfo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-group-pageinfo__button-next,
.btn-group-pageinfo__button-prev {
  height: 38px;
  padding: 0px;
  padding-block: 0px;
}

.collapse-review-logs {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 5px 15px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  z-index: 1;
  width: 100%;
  max-height: 200px;
  overflow-x: auto;
  .ant-collapse-item
    > .ant-collapse-header
    > .ant-collapse-expand-icon
    > .anticon.anticon-right.ant-collapse-arrow
    > svg {
    transform: rotate(90deg) !important;
  }

  .ant-collapse-item-active
    > .ant-collapse-header
    > .ant-collapse-expand-icon
    > .anticon.anticon-right.ant-collapse-arrow
    > svg {
    transform: rotate(-90deg) !important;
  }

  &--disabled {
    pointer-events: none;

    .ant-collapse-expand-icon {
      visibility: hidden;
    }
  }
}

.scroll-collapse {
  max-height: 50vh;
  overflow-y: auto;
}
.canvas-pageNro {
  margin-top: 5px;
  text-align: center;
}

.receipt-page-icon {
  background: transparent;
  color: #03729f;
  font-size: 38px;
}
.collapse-log-alert-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__left {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
.collapse-log-alert {
  display: flex;
  align-items: center;
  gap: 2px;
}
.review-alert-icon {
  color: #ffb156;
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0;
  font-size: 18px;
  margin-left: -1px;
}
.review-success-icon {
  color: #00c734;
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0;
  font-size: 18px;
  margin-left: -1px;
}
.pdf-canvas-item {
  position: absolute;
  background-color: transparent;
  border: 1px blue solid;
  box-sizing: content-box;
}

.pdf-canvas-item.pdf-canvas-item-active {
  border: 1px orangered solid;
}

.ant-collapse.ant-collapse-icon-position-start.ant-collapse-borderless.collapse-review-logs {
  .ant-collapse-header {
    font-weight: bold;
    align-items: center;
  }
}

@primary-color: #03729F;