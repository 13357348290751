.review-receipt-exit-modal {
  .review-receipt-exit-modal-title {
    display: flex;
    align-items: center;
  }
  .review-receipt-exit-modal-title-icon {
    color: #00c734;
    font-size: 24px;
    margin-right: 12px;
  }

  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-body {
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 0;
  }
  .ant-modal-footer {
    border-top: none;
  }
}

@primary-color: #03729F;