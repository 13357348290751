.card-indicator {
  .done {
    background-color: var(--indicator-done);
  }
  .incomplete {
    background-color: var(--indicator-incomplete);
  }
  .error {
    background-color: var(--indicator-error);
  }
  .done-variation {
    background-color: var(--indicator-done-variation);
  }
  .incomplete-variation {
    background-color: var(--indicator-incomplete-variation);
  }
  .error-variation {
    background-color: var(--indicator-error-variation);
  }
  .done-color-percentage {
    color: var(--indicator-done-variation);
  }
  .incomplete-color-percentage {
    color: var(--indicator-incomplete-variation);
  }
  .error-color-percentage {
    color: var(--indicator-error-variation);
  }
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  min-height: 300px;
  background-color: white;
  > .card-indicator__header {
    padding: 13px 21px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    color: white;
    min-height: 150px;
    height: 100%;
    > .card-indicator__header__content {
      display: flex;
      flex-direction: column;
      > .card-indicator__header__content__icon,
      .card-indicator__header__content__title {
        text-align: left;
      }
      > .card-indicator__header__content__icon {
        margin-bottom: 10px;
      }
      > .card-indicator__header__content__title {
        letter-spacing: 0.07em;
        margin-bottom: 8px;
        line-height: 19px;
      }
      > .card-indicator__header__content__container-indicators {
        display: flex;
        align-items: center;
        > .card-indicator__header__content__container-indicators__quantity {
          letter-spacing: 0.07em;
          font-weight: 700;
          font-size: 30px;
          line-height: 41px;
        }
        > .card-indicator__header__content__container-indicators__container-variation {
          height: 100%;
          margin-left: 20px;
          border-radius: 50px;
          padding: 5px 25px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  > .card-indicator__content {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: white;
    min-height: 150px;
    height: 100%;
    padding: 13px 21px;
    justify-content: space-around;

    > .card-indicator__content__title {
      letter-spacing: 0.07em;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }

    > .card-indicator__content__percentage-label {
      letter-spacing: 0.07em;
      font-weight: 400;
      font-size: 30px;
      line-height: 41px;
    }
    > .card-indicator__content__container-progressbar {
      display: flex;
      flex-direction: column;
      > .card-indicator__content__container-progressbar__progressbar-base {
        background-color: #eeeeee;
        border-radius: 50px;
        width: 100%;
        height: 10px;
        > .card-indicator__content__container-progressbar__progressbar-base__progressbar {
          width: 0%;
          clear: both;
          height: 100%;
          border-radius: inherit;
        }
      }
    }
  }
}

@primary-color: #03729F;