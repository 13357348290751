.spin-container {
  display: flex;
  justify-content: center;
  height: 50vh;
  .spin {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@primary-color: #03729F;