.integration-tenant-message-container {
  padding: 13px !important;
  .integration-tenant-error-icon{
    font-size: 44px;
    color: rgba(255, 177, 86, 1);
  }
  .GenericCard-container--information {
    gap: 10px;
  }
}

.integration-tenant-settings {
  &__icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

&__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  svg {
    width: 18px;
    height: 18px;
  }
}

}
.icon-disabled {
width: 40px;
pointer-events: none;
cursor: not-allowed;
opacity: 0.3;
filter: grayscale(100%);
}

@primary-color: #03729F;