.validation-settings {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .ant-switch-checked {
    background-color: #3baa61;
  }

  &__loading {
    padding-block-end: 100px;
  }
}

.validation-settings__content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: white;

  padding: 15px;
  border-radius: 10px;
}

@primary-color: #03729F;